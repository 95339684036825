<template>
  <div>
    <div class="terms mt-2">
      <div class="text-center pb-1">
        <h4 class="text-black title">
          {{ $t('src.components.driverpolicy.driverSopPolicy') }}
        </h4>
      </div>
      <div>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.driverpolicy.formulaForPayout') }}
        </h5>
        <ol class="list">
          <li>{{ $t('src.components.driverpolicy.onDemandDelivery') }}</li>
          <li>{{ $t('src.components.driverpolicy.nextDayDelivery') }}</li>
          <li>{{ $t('src.components.driverpolicy.distributionDeliveryPayment') }}</li>
        </ol>
      </div>
      <div class="">
        <h5 class="text-black title mb-0">
          {{ $t('src.components.driverpolicy.ensuringARespectful') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.driverpolicy.theWayYou') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('src.components.driverpolicy.hereAreSome') }}
        </p>
      </div>
      <div class="pb-1">
        <h5 class="text-black title mb-1">
          {{ $t('src.components.driverpolicy.aGologerIs') }}
        </h5>
        <ol class="list">
          <li><span class="bold">{{ $t('src.components.driverpolicy.receivingParcelImmediately') }}</span>{{ $t('src.components.driverpolicy.whenAGologer') }}</li>
          <li><span class="bold">{{ $t('src.components.driverpolicy.reportDamagedParcel') }}</span>{{ $t('src.components.driverpolicy.ifTheGologer') }}</li>
          <li><span class="bold">{{ $t('src.components.driverpolicy.maintainGoodDeliveryStandard') }}</span>{{ $t('src.components.driverpolicy.alwaysBeOn') }}</li>
          <li><span class="bold">{{ $t('src.components.driverpolicy.deliveringParcelsImmediately') }}</span>{{ $t('src.components.driverpolicy.anyDeliveriesWithin') }}</li>
          <li><span class="bold">{{ $t('src.components.driverpolicy.handleItemWithCare') }}</span>{{ $t('src.components.driverpolicy.onTheMidst') }}</li>
          <li><span class="bold">{{ $t('src.components.driverpolicy.notCancellingA') }}</span>{{ $t('src.components.driverpolicy.anyCancellationDone') }}</li>
          <li><span class="bold">{{ $t('src.components.driverpolicy.damagingUsersOr') }}</span>{{ $t('src.components.driverpolicy.forExampleDamaging') }}</li>
          <li><span class="bold">{{ $t('src.components.driverpolicy.useOfInappropriate') }}</span>{{ $t('src.components.driverpolicy.forExampleAsking') }}</li>
          <li><span class="bold">{{ $t('src.components.driverpolicy.unwantedContactWith') }}</span>{{ $t('src.components.driverpolicy.forExampleTexting') }}</li>
          <li><span class="bold">{{ $t('src.components.driverpolicy.breakingTheLocal') }}</span>{{ $t('src.components.driverpolicy.forExampleDeliver') }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.driverpolicy.termsOfUse') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.driverpolicy.asAGologer') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.driverpolicy.appUsage') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.driverpolicy.gologAppUse') }}<span class="bold">{{ $t('src.components.driverpolicy.mandatoryTurnOn') }}</span>{{ $t('src.components.driverpolicy.whilePerformingEvery') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.driverpolicy.fraudOrIllegitimateBehaviour') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.driverpolicy.fraudulentOrIllegitimate') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('src.components.driverpolicy.cancellationRate') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('src.components.driverpolicy.aDriverCancellation') }}
        </p>
        <p class="lead p-size text-black">
          <span class="bold">{{ $t('src.components.driverpolicy.howIsMy') }}</span>{{ $t('src.components.driverpolicy.yourCancellationAllowed') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
  .text-black {
    font-family: Arial, Helvetica, sans-serif;
  }
  .p-size {
    font-size: 14px !important;
  }
  .title {
    font-weight: bold;
  }
  .list li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px !important;
  }
  .bold {
    font-weight: bold;
  }
  .normal {
    font-weight: normal;
  }
  .custom-list { counter-reset: item }
  .custom-list>li:before { content: counters(item, ".") " "; counter-increment: item }

</style>
