<template>
  <div>
    <div class="terms mt-2">
      <div class="text-center pb-1">
        <h4 class="text-black title">
          {{ $t('src.components.rulespolicy.rulesCP') }}
        </h4>
      </div>
      <h5 class="text-black title">
        {{ $t('src.components.rulespolicy.formulaFP') }}
      </h5>
      <p class="lead p-size text-black">
        {{ $t('src.components.rulespolicy.theDD') }}
      </p>
      <h5 class="text-black title mb-0">
        {{ $t('src.components.rulespolicy.ensuringAR') }}
      </h5>
      <p class="lead p-size text-black">
        {{ $t('src.components.rulespolicy.theWY') }}<br /><br />{{
          $t('src.components.rulespolicy.hereAS')
        }}<br /><br />{{ $t('src.components.rulespolicy.ifWA') }}
      </p>
      <h5 class="text-black title mb-1">
        {{ $t('src.components.rulespolicy.aGI') }}
      </h5>
      <ol class="list">
        <li>
          <span class="bold">{{
            $t('src.components.rulespolicy.receivingPI')
          }}</span
          >{{ $t('src.components.rulespolicy.whenAG') }}
        </li>
        <li>
          <span class="bold">{{
            $t('src.components.rulespolicy.reportDP')
          }}</span
          >{{ $t('src.components.rulespolicy.ifTG') }}
        </li>
        <li>
          <span class="bold">{{
            $t('src.components.rulespolicy.maintainGDS')
          }}</span
          >{{ $t('src.components.rulespolicy.alwaysBO') }}
        </li>
        <li>
          <span class="bold">{{
            $t('src.components.rulespolicy.deliveringPI')
          }}</span
          >{{ $t('src.components.rulespolicy.anyDW') }}
        </li>
        <li>
          <span class="bold">{{
            $t('src.components.rulespolicy.handleIWC')
          }}</span
          >{{ $t('src.components.rulespolicy.onTM') }}
        </li>
        <li>
          <span class="bold">{{ $t('src.components.rulespolicy.notCA') }}</span
          >{{ $t('src.components.rulespolicy.anyCD') }}
        </li>
        <li>
          <span class="bold">{{
            $t('src.components.rulespolicy.damagingUO')
          }}</span
          >{{ $t('src.components.rulespolicy.forED') }}
        </li>
        <li>
          <span class="bold">{{ $t('src.components.rulespolicy.useOI') }}</span
          >{{ $t('src.components.rulespolicy.forEA') }}
        </li>
        <li>
          <span class="bold">{{
            $t('src.components.rulespolicy.unwantedCW')
          }}</span
          >{{ $t('src.components.rulespolicy.forET') }}
        </li>
        <li>
          <span class="bold">{{
            $t('src.components.rulespolicy.breakingTL')
          }}</span
          >{{ $t('src.components.rulespolicy.forEC') }}
        </li>
      </ol>
      <p class="lead p-size text-black">
        {{ $t('src.components.rulespolicy.behavior') }}
      </p>
      <h5 class="text-black title mb-0">
        {{ $t('src.components.rulespolicy.termsOU') }}
      </h5>
      <p class="lead p-size text-black">
        {{ $t('src.components.rulespolicy.asAG') }}
      </p>
      <h5 class="text-black title mb-0">
        {{ $t('src.components.rulespolicy.fraudOIB') }}
      </h5>
      <p class="lead p-size text-black">
        {{ $t('src.components.rulespolicy.fraudulentOI') }}
      </p>
      <h5 class="text-black title mb-0">
        {{ $t('src.components.rulespolicy.cancellationR') }}
      </h5>
      <p class="lead p-size text-black">
        {{ $t('src.components.rulespolicy.aDC') }}
      </p>
      <h5 class="text-black title mb-0">
        {{ $t('src.components.rulespolicy.howIM') }}
      </h5>
      <p class="lead p-size text-black">
        {{ $t('src.components.rulespolicy.yourCA') }}
      </p>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.text-black {
  font-family: Arial, Helvetica, sans-serif;
}
.p-size {
  font-size: 14px !important;
}
.title {
  font-weight: bold;
}
.main li {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px !important;
}
.list li {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px !important;
}
.bold {
  font-weight: bold;
}
.normal {
  font-weight: normal;
}
.custom-list {
  counter-reset: item;
}
.custom-list > li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
}
</style>
