<template>
  <div>
    <div class="terms mt-2">
      <div class="text-center pb-1">
        <h4 class="text-black title">
          {{ $t('refund_policy.title') }}
        </h4>
      </div>
      <p class="lead p-size text-black">
        {{ $t('refund_policy.paragraph') }}
      </p>

      <ol class="main bold">
        <li
          v-for="(parentItem, parentIndex) in $t('refund_policy.list')"
          :key="parentIndex"
          class="mb-2"
        >
          <span class="d-block">{{ $t(`refund_policy.list.${parentIndex}.title`) }}</span>
          <span
            v-if="parentItem.paragraph && (typeof parentItem.paragraph !== 'object')"
            class="font-weight-light"
          >
            {{ parentItem.paragraph }}
          </span>
          <ol
            v-if="parentItem.agenda"
            class="list normal"
            type="a"
          >
            <li
              v-for="(item, index) in parentItem.agenda"
              :key="index"
            >
              {{ item }}
            </li>
          </ol>
          <div
            v-else-if="(typeof parentItem.paragraph === 'object')"
            class="list normal"
            type="a"
          >
            <p
              v-for="(item, index) in parentItem.paragraph"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
  .text-black {
    font-family: Arial, Helvetica, sans-serif;
  }
  .p-size {
    font-size: 14px !important;
  }
  .title {
    font-weight: bold;
  }
  .main li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
  }
  .list li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px !important;
  }
  .bold {
    font-weight: bold;
  }
  .normal {
    font-weight: normal;
  }
  .custom-list { counter-reset: item }
  .custom-list>li:before { content: counters(item, ".") " "; counter-increment: item }

</style>
