import { render, staticRenderFns } from "./DataPolicy.vue?vue&type=template&id=127f479f&scoped=true&"
import script from "./DataPolicy.vue?vue&type=script&lang=js&"
export * from "./DataPolicy.vue?vue&type=script&lang=js&"
import style0 from "./DataPolicy.vue?vue&type=style&index=0&id=127f479f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127f479f",
  null
  
)

export default component.exports