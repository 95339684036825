<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>
        <validation-observer
          ref="registerForm"
          v-slot="{ passes }"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="passes(handleSubmitForm)"
          >
            <b-form-group
              :label="$t('src.views.pages.auth.registration.person_in_charge')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Person In Charge Name"
                vid="address.name"
              >
                <b-form-input
                  v-model="form.address.name"
                  :state="errors.length > 0 ? false:null"
                  name="person_in_charge"
                  :placeholder="$t('src.views.pages.auth.registration.person_in_charge')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.email')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                vid="email"
              >
                <b-form-input
                  v-model="form.email"
                  :state="errors.length > 0 ? false:null"
                  name="email"
                  :placeholder="$t('src.views.pages.auth.registration.johnexamplecom')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.phone')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Phone"
                vid="phone"
              >
                <b-form-input
                  v-model="form.phone"
                  :state="errors.length > 0 ? false:null"
                  name="phone"
                  :placeholder="$t('src.views.pages.auth.registration.xxxxxxxxx')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.companyN')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Company Name"
                vid="name"
              >
                <b-form-input
                  v-model="form.name"
                  :state="errors.length > 0 ? false:null"
                  name="name"
                  :placeholder="$t('src.views.pages.auth.registration.companyN')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.companyRN')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Company Registration"
                vid="company_registration"
              >
                <b-form-input
                  v-model="form.company_registration"
                  :state="errors.length > 0 ? false:null"
                  name="company_registration"
                  :placeholder="$t('src.views.pages.auth.registration.companyRN')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.searchA')"
            >
              <vue-google-autocomplete
                id="search-address"
                classname="form-control"
                :placeholder="$t('src.views.pages.auth.registration.searchYAH')"
                :country="['my', 'sg']"
                @placechanged="getAddressData"
              />
            </b-form-group>
            <b-form-group
              :label="$t('unit_number')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Unit No."
                vid="address.unit"
              >
                <b-form-input
                  v-model="form.address.unit"
                  :state="errors.length > 0 ? false:null"
                  name="unit"
                  :placeholder="$t('unit_number')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.addressL1')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Address Line 1"
                vid="address.address1"
              >
                <b-form-input
                  v-model="form.address.address1"
                  :state="errors.length > 0 ? false:null"
                  name="address1"
                  :placeholder="$t('src.views.pages.auth.registration.addressL1')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.addressL2')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Address Line 2"
                vid="address.address2"
              >
                <b-form-input
                  v-model="form.address.address2"
                  :state="errors.length > 0 ? false:null"
                  name="address2"
                  :placeholder="$t('src.views.pages.auth.registration.addressL2')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('src.views.pages.auth.registration.postcode')"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Postal Code"
                    vid="address.postcode"
                  >
                    <b-form-input
                      v-model="form.address.postcode"
                      :state="errors.length > 0 ? false:null"
                      name="postcode"
                      :placeholder="$t('src.views.pages.auth.registration.postalC')"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('src.views.pages.auth.registration.area')"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Area"
                    vid="address.area"
                  >
                    <b-form-input
                      v-model="form.address.area"
                      :state="errors.length > 0 ? false:null"
                      name="area"
                      :placeholder="$t('src.views.pages.auth.registration.area')"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('state')"
                >
                  <MySelectStates
                    :state="form.address.state"
                    :vid-name="'address.state'"
                    @set-selected-state="handleSetSelectedState"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('country')"
                >
                  <MySelectCountries
                    :country="form.address.country"
                    :vid-name="'address.country'"
                    @set-selected-country="handleSetSelectedCountry"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group :label="$t('src.views.pages.auth.registration.businessN')">
              <validation-provider
                v-slot="{ errors }"
                name="Business Nature"
                vid="business_type"
              >
                <b-form-group>
                  <b-form-radio-group :class="errors.length > 0 ? 'is-invalid':null">
                    <div class="d-flex justify-content-between px-2">
                      <b-form-radio
                        v-model="form.business_type"
                        plain
                        name="b2b"
                        value="1"
                      >{{ $t('src.views.pages.auth.registration.bb') }}</b-form-radio>
                      <b-form-radio
                        v-model="form.business_type"
                        plain
                        name="b2c"
                        value="2"
                      >{{ $t('src.views.pages.auth.registration.bc') }}</b-form-radio>
                      <b-form-radio
                        v-model="form.business_type"
                        plain
                        name="b2b2c"
                        value="3"
                      >{{ $t('src.views.pages.auth.registration.bbc') }}</b-form-radio>
                    </div>
                  </b-form-radio-group>
                </b-form-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.know_golog')"
            >
              <MySelectKnowGolog
                :option="form.know_golog"
                :vid-name="'know_golog'"
                @set-selected-option="handleSetSelectedKnowGolog"
              />
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.password')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Password"
                vid="password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="password"
                    :placeholder="$t('src.views.pages.auth.registration.7740')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('src.views.pages.auth.registration.confirmP')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Confirm Password"
                vid="password_confirmation"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model="form.password_confirmation"
                    :type="confirmPasswordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="password_confirmation"
                    :placeholder="$t('src.views.pages.auth.registration.8414')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="confirmPasswordToggleIcon"
                      class="cursor-pointer"
                      @click="toggleConfirmPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <hr>
            <h5 class="mb-1">
              {{ $t('src.views.pages.auth.registration.companyD') }}
            </h5>
            <b-form-group>
              <label>{{ $t('src.views.pages.auth.registration.ssmD') }}
                <b-link
                  v-b-modal.critModal
                >
                  <feather-icon
                    size="20"
                    icon="InfoIcon"
                  />
                </b-link>
              </label>
              <validation-provider
                v-slot="{ errors }"
                name="SSM Document"
                vid="smart_ssm_document"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-file
                    id="ssm"
                    v-model="smart_ssm_document"
                    :placeholder="$t('src.views.pages.auth.registration.chooseAF')"
                    :drop-placeholder="$t('src.views.pages.auth.registration.dropFH')"
                    accept=".pdf"
                    @change="handleUploadSSM"
                  />
                </b-input-group>
                <small
                  v-if="uploadingSsm"
                  id="check-validity-ssm"
                  class="text-info"
                >{{ $t('src.views.pages.auth.registration.checkingV') }}</small>
                <span
                  v-else
                  class="text-danger"
                >{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <MyPolicies />
            <b-button
              variant="primary"
              block
              :disabled="loading"
              type="submit"
            >
              <b-spinner
                v-if="loading"
                small
                class="float-left"
                :label="$t('src.views.pages.auth.registration.floatedR')"
              />{{ $t('src.views.pages.auth.registration.signU') }}</b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          <span>{{ $t('src.views.pages.auth.registration.alreadyHAA') }}</span>
          <b-link :to="{name:'login'}">
            <span>{{ $t('src.views.pages.auth.registration.signII') }}</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
    <b-modal
      id="critModal"
      ref="critSsmModal"
      ok-only
      ok-title="OK"
      centered
      size="xl"
      title="Document Criteria"
    >
      <ssm-sample />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import MyPolicies from '@/components/MyPolicies.vue'
import SsmSample from '@/components/SsmSample.vue'
import MySelectStates from '@/components/MySelectStates.vue'
import MySelectCountries from '@/components/MySelectCountries.vue'
import MySelectKnowGolog from '@/components/MySelectKnowGolog.vue'
import axios from '@axios'

export default {
  components: {
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    VueGoogleAutocomplete,
    MySelectStates,
    MySelectCountries,
    MySelectKnowGolog,
    MyPolicies,
    SsmSample,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      uploadingSsm: false,
      smart_ssm_document: null,
      form: {
        know_golog: null,
        email: null,
        phone: null,
        name: null,
        company_registration: null,
        password: null,
        password_confirmation: null,
        business_type: null,
        smart_ssm_document: null,
        address: {
          unit: null,
          address1: null,
          address2: null,
          postcode: null,
          area: null,
          state: null,
          country: null,
          name: null,
        },
      },
      modals: {
        criteria: false,
      },
      required,
    }
  },
  methods: {
    async handleSubmitForm() {
      this.loading = true
      const response = await axios.post('register/b2bpro', this.form)
      if (response.status === 422) {
        this.$refs.registerForm.setErrors(response.data.errors)
        this.$swal({
          icon: 'error',
          title: 'Validation Error',
          text: 'Please check required fields.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
        this.loading = false
        return
      }

      if (response.status === 201) {
        this.$swal({
          icon: 'success',
          title: 'Success',
          text: 'Successfully Created An Account',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(res => {
          if (res) {
            this.$router.replace({ name: 'login' })
          }
        })
      }
      this.loading = false
    },
    getAddressData(addressData, placeResultData) {
      const address = {
        unit: `${addressData.street_number ? `${addressData.street_number}` : ''}`,
        address1: `${addressData.street_number ? `${addressData.street_number}, ` : ''}${addressData.route}`,
        address2: placeResultData?.vicinity,
        postcode: addressData?.postal_code,
        area: addressData?.locality,
        state: addressData?.administrative_area_level_1,
        country: addressData?.country,
      }
      this.form.address = { ...this.form.address, ...address }
    },
    async handleUploadSSM(event) {
      const { files } = event.target
      const formData = new FormData()
      formData.append('ssm', files[0])
      this.uploadingSsm = true
      const response = await axios.post('/upload-ssm', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.status === 200) {
        this.form.smart_ssm_document = response.data
        this.uploadingSsm = false
      }
    },
    handleSetSelectedCountry(value) {
      this.form.address.country = value
    },
    handleSetSelectedKnowGolog(value) {
      this.form.know_golog = value
    },
    handleSetSelectedState(value) {
      this.form.address.state = value
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.policy-modal header{
  display: none;
}
.policy-modal footer{
  justify-content: center;
}

</style>
<style scoped>
  .auth-wrapper.auth-v1 .auth-inner {
  max-width: 600px;
}
</style>
<style lang="scss">
</style>
