<template>
  <div>
    <hr>
    <h5>{{ $t('src.views.pages.auth.registration.policy') }}</h5>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Data Privacy Policy"
            rules="required"
          >
            <div
              class="form-check form-check-inline"
              style="font-size:13px;"
            >
              <input
                ref="dpp"
                v-model="dpp"
                name="dpp"
                type="checkbox"
                class="form-check-input"
                true-value="yes"
                false-value=""
                @click="showModal"
              >{{ $t('src.views.pages.auth.registration.iATN') }}
              <b-link
                v-b-modal.dpp
                class="text-spacer"
              >
                {{ $t('src.views.pages.auth.registration.dataPP') }}
              </b-link>
            </div>
            <br>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Privacy Terms Policy"
            rules="required"
          >
            <div
              class="form-check form-check-inline"
              style="font-size:13px;"
            >
              <input
                ref="ptp"
                v-model="ptp"
                name="ptp"
                type="checkbox"
                class="form-check-input"
                true-value="yes"
                false-value=""
                @click="showModal"
              >{{ $t('src.views.pages.auth.registration.iATN') }}
              <b-link
                v-b-modal.ptp
                class="text-spacer"
              >
                {{ $t('src.views.pages.auth.registration.privacyTP') }}
              </b-link>
            </div>
            <br>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Refund Policy"
            rules="required"
          >
            <div
              class="form-check form-check-inline"
              style="font-size:13px;"
            >
              <input
                ref="rp"
                v-model="rp"
                name="rp"
                type="checkbox"
                class="form-check-input"
                true-value="yes"
                false-value=""
                @click="showModal"
              >{{ $t('src.views.pages.auth.registration.iATN') }}
              <b-link
                v-b-modal.rp
                class="text-spacer"
              >
                {{ $t('src.views.pages.auth.registration.refundP') }}
              </b-link>
            </div>
            <br>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Rules & Conduct Policy"
            rules="required"
          >
            <div
              class="form-check form-check-inline"
              style="font-size:13px;"
            >
              <input
                ref="rcp"
                v-model="rcp"
                name="rcp"
                type="checkbox"
                class="form-check-input"
                true-value="yes"
                false-value=""
                @click="showModal"
              >{{ $t('src.views.pages.auth.registration.iATN') }}
              <b-link
                v-b-modal.rcp
                class="text-spacer"
              >
                {{ $t('src.views.pages.auth.registration.rulesCP') }}
              </b-link>
            </div>
            <br>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal
      id="ptp"
      v-model="modals.ptp"
      content-class="policy-modal"
      ok-only
      :ok-title="$t('src.views.pages.auth.registration.agree')"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree"
    >
      <b-card-text>
        <div class="container">
          <privacy-policy />
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="driver-policy"
      content-class="policy-modal"
      ok-only
      :ok-title="$t('src.views.pages.auth.registration.agree')"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree"
    >
      <b-card-text>
        <div class="container">
          <driver-policy />
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="dpp"
      v-model="modals.dpp"
      content-class="policy-modal"
      ok-only
      :ok-title="$t('src.views.pages.auth.registration.agree')"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree"
    >
      <b-card-text>
        <div class="container">
          <data-policy />
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="rp"
      v-model="modals.rp"
      content-class="policy-modal"
      ok-only
      :ok-title="$t('src.views.pages.auth.registration.agree')"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree"
    >
      <b-card-text>
        <div class="container">
          <refund-policy />
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="rcp"
      v-model="modals.rcp"
      content-class="policy-modal"
      ok-only
      :ok-title="$t('src.views.pages.auth.registration.agree')"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree"
    >
      <b-card-text>
        <div class="container">
          <rules-policy />
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
import DriverPolicy from '@/components/DriverPolicy.vue'
import DataPolicy from '@/components/DataPolicy.vue'
import RefundPolicy from '@/components/RefundPolicy.vue'
import RulesPolicy from '@/components/RulesPolicy.vue'

export default {
  name: 'MyPolicies',
  components: {
    ValidationProvider,
    PrivacyPolicy,
    DriverPolicy,
    DataPolicy,
    RefundPolicy,
    RulesPolicy,
  },
  data() {
    return {
      required,
      dsp: null,
      ptp: null,
      rcp: null,
      dpp: null,
      rp: null,
      modals: {
        rp: false,
        dpp: false,
        ptp: false,
        rcp: false,
      },
    }
  },
  methods: {
    showModal(e) {
      this.modals[e.target.name] = e.target.checked
    },
    agree(e) {
      this[e.target.id] = 'yes'
      this.$refs[e.target.id].checked = true
    },
  },
}
</script>
