<template>
  <validation-provider
    v-slot="{ errors }"
    :vid="vidName"
    name="Know_Golog"
  >
    <v-select
      v-model="selectedOption"
      :options="options"
      :clearable="false"
      label="text"
      :reduce="option => option.value"
      :placeholder="$t('src.views.pages.auth.registration.know_golog')"
      :disabled="!isEditable"
      @input="handleSetSelectedOption"
    />
    <span class="text-danger">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'MySelectKnowGolog',
  components: {
    vSelect,
    ValidationProvider,

  },
  props: {
    option: {
      type: String,
      default: '',
    },
    vidName: {
      type: String,
      default: 'option',
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [
        { text: 'Email', value: 'Email' },
        { text: 'Facebook', value: 'Facebook' },
        { text: 'Instagram', value: 'Instagram' },
        { text: 'Twitter', value: 'Twitter' },
        { text: 'MYDIN', value: 'MYDIN' },
      ],
      selectedOption: null,
    }
  },
  watch: {
    option(newValue) {
      this.selectedOption = newValue
    },
  },
  mounted() {
    this.selectedOption = this.option
  },
  methods: {
    handleSetSelectedOption() {
      this.$emit('set-selected-option', this.selectedOption)
    },
  },
}
</script>
