var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return passes(_vm.handleSubmitForm)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.person_in_charge')}},[_c('validation-provider',{attrs:{"name":"Person In Charge Name","vid":"address.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"person_in_charge","placeholder":_vm.$t('src.views.pages.auth.registration.person_in_charge')},model:{value:(_vm.form.address.name),callback:function ($$v) {_vm.$set(_vm.form.address, "name", $$v)},expression:"form.address.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.email')}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"email","placeholder":_vm.$t('src.views.pages.auth.registration.johnexamplecom')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.phone')}},[_c('validation-provider',{attrs:{"name":"Phone","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"phone","placeholder":_vm.$t('src.views.pages.auth.registration.xxxxxxxxx')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.companyN')}},[_c('validation-provider',{attrs:{"name":"Company Name","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"name","placeholder":_vm.$t('src.views.pages.auth.registration.companyN')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.companyRN')}},[_c('validation-provider',{attrs:{"name":"Company Registration","vid":"company_registration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"company_registration","placeholder":_vm.$t('src.views.pages.auth.registration.companyRN')},model:{value:(_vm.form.company_registration),callback:function ($$v) {_vm.$set(_vm.form, "company_registration", $$v)},expression:"form.company_registration"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.searchA')}},[_c('vue-google-autocomplete',{attrs:{"id":"search-address","classname":"form-control","placeholder":_vm.$t('src.views.pages.auth.registration.searchYAH'),"country":['my', 'sg']},on:{"placechanged":_vm.getAddressData}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('unit_number')}},[_c('validation-provider',{attrs:{"name":"Unit No.","vid":"address.unit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"unit","placeholder":_vm.$t('unit_number')},model:{value:(_vm.form.address.unit),callback:function ($$v) {_vm.$set(_vm.form.address, "unit", $$v)},expression:"form.address.unit"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.addressL1')}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"address.address1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"address1","placeholder":_vm.$t('src.views.pages.auth.registration.addressL1')},model:{value:(_vm.form.address.address1),callback:function ($$v) {_vm.$set(_vm.form.address, "address1", $$v)},expression:"form.address.address1"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.addressL2')}},[_c('validation-provider',{attrs:{"name":"Address Line 2","vid":"address.address2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"address2","placeholder":_vm.$t('src.views.pages.auth.registration.addressL2')},model:{value:(_vm.form.address.address2),callback:function ($$v) {_vm.$set(_vm.form.address, "address2", $$v)},expression:"form.address.address2"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.postcode')}},[_c('validation-provider',{attrs:{"name":"Postal Code","vid":"address.postcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"postcode","placeholder":_vm.$t('src.views.pages.auth.registration.postalC')},model:{value:(_vm.form.address.postcode),callback:function ($$v) {_vm.$set(_vm.form.address, "postcode", $$v)},expression:"form.address.postcode"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.area')}},[_c('validation-provider',{attrs:{"name":"Area","vid":"address.area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"area","placeholder":_vm.$t('src.views.pages.auth.registration.area')},model:{value:(_vm.form.address.area),callback:function ($$v) {_vm.$set(_vm.form.address, "area", $$v)},expression:"form.address.area"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('state')}},[_c('MySelectStates',{attrs:{"state":_vm.form.address.state,"vid-name":'address.state'},on:{"set-selected-state":_vm.handleSetSelectedState}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('country')}},[_c('MySelectCountries',{attrs:{"country":_vm.form.address.country,"vid-name":'address.country'},on:{"set-selected-country":_vm.handleSetSelectedCountry}})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.businessN')}},[_c('validation-provider',{attrs:{"name":"Business Nature","vid":"business_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-radio-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('div',{staticClass:"d-flex justify-content-between px-2"},[_c('b-form-radio',{attrs:{"plain":"","name":"b2b","value":"1"},model:{value:(_vm.form.business_type),callback:function ($$v) {_vm.$set(_vm.form, "business_type", $$v)},expression:"form.business_type"}},[_vm._v(_vm._s(_vm.$t('src.views.pages.auth.registration.bb')))]),_c('b-form-radio',{attrs:{"plain":"","name":"b2c","value":"2"},model:{value:(_vm.form.business_type),callback:function ($$v) {_vm.$set(_vm.form, "business_type", $$v)},expression:"form.business_type"}},[_vm._v(_vm._s(_vm.$t('src.views.pages.auth.registration.bc')))]),_c('b-form-radio',{attrs:{"plain":"","name":"b2b2c","value":"3"},model:{value:(_vm.form.business_type),callback:function ($$v) {_vm.$set(_vm.form, "business_type", $$v)},expression:"form.business_type"}},[_vm._v(_vm._s(_vm.$t('src.views.pages.auth.registration.bbc')))])],1)])],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.know_golog')}},[_c('MySelectKnowGolog',{attrs:{"option":_vm.form.know_golog,"vid-name":'know_golog'},on:{"set-selected-option":_vm.handleSetSelectedKnowGolog}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.password')}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"password","placeholder":_vm.$t('src.views.pages.auth.registration.7740')},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('src.views.pages.auth.registration.confirmP')}},[_c('validation-provider',{attrs:{"name":"Confirm Password","vid":"password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":_vm.confirmPasswordFieldType,"state":errors.length > 0 ? false:null,"name":"password_confirmation","placeholder":_vm.$t('src.views.pages.auth.registration.8414')},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.confirmPasswordToggleIcon},on:{"click":_vm.toggleConfirmPasswordVisibility}})],1)],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('hr'),_c('h5',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('src.views.pages.auth.registration.companyD'))+" ")]),_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('src.views.pages.auth.registration.ssmD'))+" "),_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.critModal",modifiers:{"critModal":true}}]},[_c('feather-icon',{attrs:{"size":"20","icon":"InfoIcon"}})],1)],1),_c('validation-provider',{attrs:{"name":"SSM Document","vid":"smart_ssm_document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-file',{attrs:{"id":"ssm","placeholder":_vm.$t('src.views.pages.auth.registration.chooseAF'),"drop-placeholder":_vm.$t('src.views.pages.auth.registration.dropFH'),"accept":".pdf"},on:{"change":_vm.handleUploadSSM},model:{value:(_vm.smart_ssm_document),callback:function ($$v) {_vm.smart_ssm_document=$$v},expression:"smart_ssm_document"}})],1),(_vm.uploadingSsm)?_c('small',{staticClass:"text-info",attrs:{"id":"check-validity-ssm"}},[_vm._v(_vm._s(_vm.$t('src.views.pages.auth.registration.checkingV')))]):_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('MyPolicies'),_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.loading,"type":"submit"}},[(_vm.loading)?_c('b-spinner',{staticClass:"float-left",attrs:{"small":"","label":_vm.$t('src.views.pages.auth.registration.floatedR')}}):_vm._e(),_vm._v(_vm._s(_vm.$t('src.views.pages.auth.registration.signU')))],1)],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('src.views.pages.auth.registration.alreadyHAA')))]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(_vm._s(_vm.$t('src.views.pages.auth.registration.signII')))])])],1)],1)],1),_c('b-modal',{ref:"critSsmModal",attrs:{"id":"critModal","ok-only":"","ok-title":"OK","centered":"","size":"xl","title":"Document Criteria"}},[_c('ssm-sample')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }