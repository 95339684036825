<template>
  <div>
    <div class="terms mt-2">
      <div class="text-center pb-1">
        <h4 class="text-black title">
          {{ $t('data_privacy_policy.title') }}
        </h4>
      </div>
      <div>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.first_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.first_statement.paragraph_1') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.first_statement.paragraph_2') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.first_statement.paragraph_3') }}
        </p>
      </div>
      <div class="">
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.second_statement.title') }}
        </h5>
        <p class="lead p-size text-black mb-0">
          {{ $t('data_privacy_policy.second_statement.paragraph') }}
        </p>
        <ol class="list">
          <li
            v-for="(item, index) in $t('data_privacy_policy.second_statement.list')"
            :key="index"
          >
            {{ item }}
          </li>
        </ol>
      </div>
      <div>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.third_statement.title') }}
        </h5>
        <p class="lead p-size text-black mb-0">
          {{ $t('data_privacy_policy.second_statement.paragraph') }}
        </p>
        <ol class="list">
          <li
            v-for="(item, index) in $t('data_privacy_policy.third_statement.list')"
            :key="index"
            class="mb-1"
          >
            {{ item.title }}
            <ol
              v-if="item.agenda"
              class="list"
            >
              <li
                v-for="(agenda, agenda_index) in item.agenda"
                :key="agenda_index"
              >
                {{ agenda }}
              </li>
            </ol>
            <p
              v-else
              class="lead p-size text-black mb-0"
            >
              {{ item.paragraph }}
            </p>
          </li>
        </ol>
      </div>
      <div class="pb-1">
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.fourth_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.fourth_statement.paragraph') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.fifth_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.fifth_statement.paragraph_1') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.fifth_statement.paragraph_2') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.sixth_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.sixth_statement.paragraph') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.seventh_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.seventh_statement.paragraph') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.eighth_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.eighth_statement.paragraph') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.ninth_statement.title') }}
        </h5>
        <p class="lead p-size text-black mb-0">
          {{ $t('data_privacy_policy.ninth_statement.paragraph') }}
        </p>
        <ol class="list">
          <li
            v-for="(item, index) in $t('data_privacy_policy.ninth_statement.list')"
            :key="index"
          >{{ item }}</li>
        </ol>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.tenth_statement.title') }}
        </h5>
        <p class="lead p-size text-black mb-0">
          {{ $t('data_privacy_policy.tenth_statement.paragraph_1') }}
        </p>
        <ol class="list">
          <li
            v-for="(item, index) in $t('data_privacy_policy.tenth_statement.list')"
            :key="index"
          >{{ item }}</li>
        </ol>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.tenth_statement.paragraph_2') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.tenth_statement.form.data_1') }} <br>
          {{ $t('data_privacy_policy.tenth_statement.form.data_2') }} <br>
          {{ $t('data_privacy_policy.tenth_statement.form.data_3') }} <br>
          {{ $t('data_privacy_policy.tenth_statement.form.data_4') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.tenth_statement.paragraph_3') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.eleventh_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.eleventh_statement.paragraph_1') }}
        </p>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.eleventh_statement.paragraph_2') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.twelfth_statement.title') }}
        </h5>
        <p class="lead p-size text-black mb-2">
          {{ $t('data_privacy_policy.twelfth_statement.paragraph') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.thirteenth_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.thirteenth_statement.paragraph') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.fourteenth_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.fourteenth_statement.paragraph') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.fifteenth_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.fifteenth_statement.paragraph') }}
        </p>
        <h5 class="text-black title mb-0">
          {{ $t('data_privacy_policy.sixteenth_statement.title') }}
        </h5>
        <p class="lead p-size text-black">
          {{ $t('data_privacy_policy.sixteenth_statement.paragraph') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
  .text-black {
    font-family: Arial, Helvetica, sans-serif;
  }
  .p-size {
    font-size: 14px !important;
  }
  .title {
    font-weight: bold;
  }
  .list li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px !important;
  }
  .bold {
    font-weight: bold;
  }
  .normal {
    font-weight: normal;
  }
  .custom-list { counter-reset: item }
  .custom-list>li:before { content: counters(item, ".") " "; counter-increment: item }

</style>
